import { history } from "../../../history";
import { getAxiosWithUrl } from "api/config";
import { toast } from "react-toastify";

const API = {
  LOGIN: `/api/users/login`,
};

export const login = (user) => {
  return (dispatch) => {
    dispatch({ type: "LOADING" });
    getAxiosWithUrl()
      .post(API.LOGIN, user)
      .then((response) => {
        if (response.data && response.data.token) {
          const { token, username } = response.data;

          localStorage.setItem("username", username);
          localStorage.setItem("token", token);

          dispatch({
            type: "LOGIN",
            payload: { username, token },
          });

          toast.success(`Logged In, Welcome ${username}!`);
          history.push("/");
        } else {
          toast.error(response.data.message);
          dispatch({ type: "DONE" });
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        dispatch({ type: "DONE" });
      });
  };
};

export const logout = () => {
  return (dispatch) => {
    localStorage.removeItem("username");
    localStorage.removeItem("token");

    dispatch({ type: "LOGOUT" });
    history.push("/login");
  };
};
