import { useSelector, useDispatch } from "react-redux";
import * as actions from "redux/actions/auth/loginActions";

export const useAuth = () => {
  const { username, token, isAuthenticated, isLoading } = useSelector(
    (state) => state.auth
  );
  const dispatch = useDispatch();

  const login = (values) => {
    dispatch(actions.login(values));
  };

  const logout = () => {
    dispatch(actions.logout());
  };

  return {
    isAuthenticated,
    username,
    isLoading,
    token,
    login,
    logout,
  };
};
