import axios from "axios";
import { useAuth } from "redux/hooks/auth";

// const { protocol, port, hostname } = window.location;
// const _host = hostname.split(".").slice(-2).join(".");
// export const baseURL = `${protocol}//api.${_host}${port ? ":" + port : ""}`;

// export const baseURL = "http://localhost:8000";
export const baseURL = "https://api.skyhawks.biz";

export const useAxios = () => {
  const { token } = useAuth();
  return axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
    baseURL,
  });
};

export const getAxiosWithUrl = () =>
  axios.create({
    baseURL,
  });
