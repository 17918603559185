import messages_en from "assets/data/locales/en.json";

export const menu_messages = {
  en: messages_en,
};

const languageConfig = {
  en: {
    name: "English",
    dir: "ltr",
    countryCode: "us",
  },
};

export default languageConfig;
