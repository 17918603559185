const getInitialState = () => {
  const username = localStorage.getItem("username");
  const token = localStorage.getItem("token");

  if (username && token) {
    return {
      username,
      token,
      isAuthenticated: true,
      isLoading: false,
    };
  }
  return {
    isAuthenticated: false,
    isLoading: false,
  };
};

export const login = (state = getInitialState(), action) => {
  switch (action.type) {
    case "LOGIN": {
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        isLoading: false,
      };
    }
    case "LOGOUT": {
      return {
        isLoading: false,
        isAuthenticated: false,
      };
    }
    case "LOADING": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "DONE": {
      return {
        ...state,
        isLoading: false,
      };
    }
    default: {
      return state;
    }
  }
};
