import React, { Suspense, lazy } from "react";
import { Router, Switch, Route } from "react-router-dom";
import { history } from "./history";
import { connect } from "react-redux";
import Spinner from "./components/@vuexy/spinner/Loading-spinner";
import { ContextLayout } from "./utility/context/Layout";

// Route-based code splitting
import { AuthComponent } from "AuthComponent";

const WelcomePage = lazy(() => import("./views/pages/Welcome"));
const AddSliderPage = lazy(() => import("./views/pages/home/AddSliderPage"));
const SlidersPage = lazy(() => import("./views/pages/home/SlidersPage"));
const VideoPage = lazy(() => import("./views/pages/home/VideoPage"));
const AddProductPage = lazy(() =>
  import("./views/pages/products/add/AddProductPage")
);
const EditProductPage = lazy(() =>
  import("./views/pages/products/edit/EditProductPage")
);
const ViewProductsPage = lazy(() =>
  import("./views/pages/products/view/ViewProductsPage")
);
const AddBrandPage = lazy(() =>
  import("./views/pages/brands/add/AddBrandPage")
);
const EditBrandPage = lazy(() =>
  import("./views/pages/brands/edit/EditBrandPage")
);
const ViewBrandsPage = lazy(() =>
  import("./views/pages/brands/view/ViewBrandsPage")
);
const GalleryPage = lazy(() => import("./views/pages/gallery/GalleryPage"));
const AboutUs = lazy(() => import("./views/pages/about-us/AboutUs"));
const Branches = lazy(() => import("./views/pages/branches/BranchesPage"));
const Services = lazy(() => import("./views/pages/services/Services"));
const ContactUsPage = lazy(() =>
  import("./views/pages/contact-us/ContactUsPage")
);
const SocialLinks = lazy(() =>
  import("./views/pages/social-links/SocialLinks")
);
const AccountPage = lazy(() => import("./views/pages/account/AccountPage"));
const login = lazy(() => import("./views/pages/authentication/login/Login"));

const error404 = lazy(() => import("./views/pages/misc/error/404"));
const error500 = lazy(() => import("./views/pages/misc/error/500"));
const authorized = lazy(() => import("./views/pages/misc/NotAuthorized"));
const maintenance = lazy(() => import("./views/pages/misc/Maintenance"));

// Set Layout and Component Using App Route
const RouteConfig = ({
  component: Component,
  fullLayout,
  permission,
  auth,
  isPrivate,
  ...rest
}) => {
  const ToRender = () => (
    <Route
      {...rest}
      render={(props) => {
        return (
          <ContextLayout.Consumer>
            {(context) => {
              let LayoutTag =
                fullLayout === true
                  ? context.fullLayout
                  : context.state.activeLayout === "horizontal"
                  ? context.horizontalLayout
                  : context.VerticalLayout;
              return (
                <LayoutTag {...props}>
                  <Suspense fallback={<Spinner />}>
                    <Component {...props} />
                  </Suspense>
                </LayoutTag>
              );
            }}
          </ContextLayout.Consumer>
        );
      }}
    />
  );

  if (isPrivate) {
    return (
      <AuthComponent>
        <ToRender />
      </AuthComponent>
    );
  }
  return <ToRender />;
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const AppRoute = connect(mapStateToProps)(RouteConfig);

class AppRouter extends React.Component {
  render() {
    return (
      // Set the directory path if you are deploying in sub-folder
      <Router history={history}>
        <Switch>
          <AppRoute exact path="/" component={WelcomePage} isPrivate />
          <AppRoute
            exact
            path="/home/sliders/add"
            component={AddSliderPage}
            isPrivate
          />
          <AppRoute
            exact
            path="/home/sliders"
            component={SlidersPage}
            isPrivate
          />
          <AppRoute exact path="/home/video" component={VideoPage} isPrivate />
          <AppRoute
            exact
            path="/products/add"
            component={AddProductPage}
            isPrivate
          />
          <AppRoute
            exact
            path="/products/edit"
            component={EditProductPage}
            isPrivate
          />
          <AppRoute
            exact
            path="/products/view"
            component={ViewProductsPage}
            isPrivate
          />
          <AppRoute
            exact
            path="/brands/add"
            component={AddBrandPage}
            isPrivate
          />
          <AppRoute
            exact
            path="/brands/edit"
            component={EditBrandPage}
            isPrivate
          />
          <AppRoute
            exact
            path="/brands/view"
            component={ViewBrandsPage}
            isPrivate
          />
          <AppRoute exact path="/gallery" component={GalleryPage} isPrivate />
          <AppRoute exact path="/about-us" component={AboutUs} isPrivate />
          <AppRoute exact path="/branches" component={Branches} isPrivate />
          <AppRoute exact path="/services" component={Services} isPrivate />
          <AppRoute
            exact
            path="/contact-us"
            component={ContactUsPage}
            isPrivate
          />
          <AppRoute
            exact
            path="/social-links"
            component={SocialLinks}
            isPrivate
          />
          <AppRoute exact path="/account" component={AccountPage} isPrivate />

          <AppRoute path="/login" component={login} fullLayout />
          <AppRoute path="/misc/error/500" component={error500} fullLayout />
          <AppRoute
            path="/misc/not-authorized"
            component={authorized}
            fullLayout
          />
          <AppRoute
            path="/misc/maintenance"
            component={maintenance}
            fullLayout
          />

          <AppRoute component={error404} fullLayout />
        </Switch>
      </Router>
    );
  }
}

export default AppRouter;
