import React from "react";
import { BiSlideshow } from "react-icons/bi";
import {
  AiOutlineBranches,
  AiFillHome,
  AiOutlineAppstoreAdd,
  AiFillInfoCircle,
} from "react-icons/ai";
import { BsFillCameraVideoFill } from "react-icons/bs";
import {
  MdProductionQuantityLimits,
  MdAddAPhoto,
  MdManageAccounts,
} from "react-icons/md";
import { HiViewGrid } from "react-icons/hi";
import { SiBrandfolder } from "react-icons/si";
import { IoMdAddCircle } from "react-icons/io";
import { GrGallery, GrView, GrServices, GrContact } from "react-icons/gr";
import { TiSocialFacebookCircular } from "react-icons/ti";

const navigationConfig = [
  {
    id: "home_page",
    title: "home_page",
    type: "collapse",
    icon: <AiFillHome fontSize={22} />,
    children: [
      {
        id: "add_slide",
        title: "add_slide",
        type: "item",
        icon: <MdAddAPhoto fontSize={22} />,
        navLink: "/home/sliders/add",
      },
      {
        id: "slides",
        title: "slides",
        type: "item",
        icon: <BiSlideshow fontSize={22} />,
        navLink: "/home/sliders",
      },
      {
        id: "video",
        title: "video",
        type: "item",
        icon: <BsFillCameraVideoFill fontSize={22} />,
        navLink: "/home/video",
      },
    ],
  },
  {
    id: "products",
    title: "products",
    type: "collapse",
    icon: <MdProductionQuantityLimits fontSize={22} />,
    children: [
      {
        id: "add_product",
        title: "add_product",
        type: "item",
        icon: <AiOutlineAppstoreAdd fontSize={22} />,
        navLink: "/products/add",
      },
      {
        id: "view_products",
        title: "view_products",
        type: "item",
        icon: <HiViewGrid fontSize={22} />,
        navLink: "/products/view",
      },
    ],
  },
  {
    id: "brands",
    title: "brands",
    type: "collapse",
    icon: <SiBrandfolder fontSize={22} />,
    children: [
      {
        id: "add_brand",
        title: "add_brand",
        type: "item",
        icon: <IoMdAddCircle fontSize={22} />,
        navLink: "/brands/add",
      },
      {
        id: "view_brands",
        title: "view_brands",
        type: "item",
        icon: <GrView fontSize={22} />,
        navLink: "/brands/view",
      },
    ],
  },
  {
    id: "gallery",
    title: "gallery",
    type: "item",
    icon: <GrGallery fontSize={22} />,
    navLink: "/gallery",
  },
  {
    id: "about_us",
    title: "about_us",
    type: "item",
    icon: <AiFillInfoCircle fontSize={22} />,
    navLink: "/about-us",
  },
  {
    id: "branches",
    title: "branches",
    type: "item",
    icon: <AiOutlineBranches fontSize={22} />,
    navLink: "/branches",
  },
  {
    id: "services",
    title: "services",
    type: "item",
    icon: <GrServices fontSize={22} />,
    navLink: "/services",
  },
  {
    id: "contact_us",
    title: "contact_us",
    type: "item",
    icon: <GrContact fontSize={22} />,
    navLink: "/contact-us",
  },
  {
    id: "social_links",
    title: "social_links",
    type: "item",
    icon: <TiSocialFacebookCircular fontSize={22} />,
    navLink: "/social-links",
  },
  {
    id: "account",
    title: "account",
    type: "item",
    icon: <MdManageAccounts fontSize={22} />,
    navLink: "/account",
  },
];

export default navigationConfig;
